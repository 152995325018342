import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

export function Footer() {
  return (
    <Box sx={{ position: "relative", minHeight: "500px" }}>
      {/* Background Image Section */}
      <Box
        sx={{
          backgroundImage:
            "url(https://superteacher.in/public/frontend/kids/images/bg.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      />

      {/* Content Overlay */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          maxWidth: "1200px",
          margin: "0 auto",
          paddingX: { xs: 2, md: 4 },
          paddingY: { xs: 2, md: 4 },
          color: "white",
          display: "grid",
          gap: 4,
          gridTemplateColumns: {
            xs: "1fr", // Single column for small screens
            sm: "repeat(2, 1fr)", // Two columns for small to medium screens
            md: "repeat(3, 1fr)", // Three columns for medium screens
            lg: "repeat(4, 1fr)", // Four columns for large screens
          },
        }}
      >
        {/* Column 1 */}
        <Box>
          <img
            src="https://superteacher.in/public/frontend/kids/images/super_teacher_logo_new.png"
            alt="SuperTeacher Logo"
            height={80}
          />
          <Box
            sx={{
              fontSize: "14px",
              borderRadius: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
            }}
          >
            <p> The SUPERTEACHER team is dedicated to equip teachers with the
            all-round skills, which will in turn help them play their role to
            the best of their capability. </p>
          </Box>
        </Box>

        {/* Column 2 */}
        <Box>
          <Box
            sx={{ fontFamily: "Comfortaa", fontSize: "18px", fontWeight: "bold" }}
          >
            Contact Info
          </Box>
          <Box
            component="address"
            sx={{
              fontStyle: "normal",
              fontSize: "14px",
              borderRadius: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
          >
            <Box sx={{ py:2 }}>
              8-107/12, M-park residency, Shilpa Nagar, Nagaram,
              Hyderabad-500083
            </Box>
            <Box sx={{ marginBottom: "8px" }}>1800 123 7765, 9908442000</Box>
            <Box>connect@superteacher.in</Box>
          </Box>
        </Box>

        {/* Column 3 */}
        <Box>
          <Box
            sx={{ fontFamily: "Comfortaa", fontSize: "18px", fontWeight: "bold" }}
          >
            Useful Links
          </Box>
          <Box component="nav" sx={{py:2}}>
            <Box
              component="ul"
              sx={{
                listStyle: "none",
                padding: 0,
                margin: 0,
                fontSize: "14px",
                // backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: 1,
              }}
            >
              <Box component="li" sx={{ marginBottom: "8px" }}>
                <Link
                  href="/about"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  About us
                </Link>
              </Box>
              <Box component="li" sx={{ marginBottom: "8px" }}>
                <Link
                  href="/courses"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  Our Courses
                </Link>
              </Box>
              <Box component="li" sx={{ marginBottom: "8px" }}>
                <Link
                  href="/terms"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  Terms and Conditions
                </Link>
              </Box>
              <Box component="li" sx={{ marginBottom: "8px" }}>
                <Link
                  href="/privacy"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box component="li">
                <Link
                  href="/contact"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  Contact Us
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Column 4 */}
        <Box>
          <Box
            sx={{ fontFamily: "Comfortaa", fontSize: "18px", fontWeight: "bold" }}
          >
            Get in Touch
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              py:2,
              borderRadius: 1,
              // backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
          >
            <Link
              href="https://facebook.com"
              aria-label="Facebook"
              sx={{ color: "white", "&:hover": { color: "#1877f2" } }}
            >
              <FacebookIcon sx={{ fontSize: "24px" }} />
            </Link>
            <Link
              href="mailto:connect@superteacher.in"
              aria-label="Email"
              sx={{ color: "white", "&:hover": { color: "#c71610" } }}
            >
              <MailIcon sx={{ fontSize: "24px" }} />
            </Link>
            <Link
              href="https://linkedin.com"
              aria-label="LinkedIn"
              sx={{ color: "white", "&:hover": { color: "#0a66c2" } }}
            >
              <LinkedInIcon sx={{ fontSize: "24px" }} />
            </Link>
            <Link
              href="https://twitter.com"
              aria-label="Twitter"
              sx={{ color: "white", "&:hover": { color: "#1da1f2" } }}
            >
              <TwitterIcon sx={{ fontSize: "24px" }} />
            </Link>
          </Box>
        </Box>
      </Box>

      {/* Copyright Section */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#3a96d7",
          color: "white",
          padding: "15px",
          textAlign: "center",
          zIndex: 2,
        }}
      >
        Copyright 2024 © SuperTeacher
      </Box>
    </Box>
  );
}