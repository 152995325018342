import { Box } from "@mui/material";
import React from "react";
import BookkeepingSystem from "./A2_TabLayout";
import { Header } from "../0_layout/HeaderFooter";
import BackButton from '../0_microelements/A3_BackButton';


export function BookLayout() {
  return(
    <>
    <Header/>
      <Box sx={{m:'30px', mt:'200px'}}>
        <BookkeepingSystem/>

         {/* Back Button */}
        <Box sx={{textAlign:'end', my:'50px'}}>
          <BackButton color='#f5f5f5'/>
        </Box>

      </Box>
    </>
  )

}