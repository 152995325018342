import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';

const RotatedSquare = styled(Box)(({ theme, color }) => ({
  width: '100px',
  height: '100px',
  backgroundColor: color,
  transform: 'rotate(45deg)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  borderRadius:'7px',
  [theme.breakpoints.down('sm')]: {
    width: '80px',
    height: '80px',
  },
}));

const SquareContent = styled(Typography)({
  transform: 'rotate(-45deg)',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '1.3rem',
});

const SquareText = styled(Typography)(({ theme, color }) => ({
  marginTop: theme.spacing(4),
  color: color,
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '1.3rem',
}));

const SquareItem = ({ number, text, color }) => (
  <Grid item xs={6} sm={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <RotatedSquare color={color}>
      <SquareContent>{number}</SquareContent>
    </RotatedSquare>
    <SquareText color={color}>{text}</SquareText>
  </Grid>
);

export const HNumbers = () => {
  const squares = [
    { number: '1400+', text: 'Teachers', color: '#FD4D40' },
    { number: '500+', text: 'Schools', color: '#F1C22E' },
    { number: 28, text: 'States', color: '#16C3B0' },
    { number: 13, text: 'Countries', color: '#2DB3D9' },
  ];

  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          {squares.map((square, index) => (
            <SquareItem key={index} {...square} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
