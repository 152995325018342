import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import { Box } from "@mui/material";
import TeachersOfferings from "./TeachersOfferings";

export default function offeringLayout() {
  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>

      {/* Offerings   */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <TeachersOfferings />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {/* Footer*/}
      <Footer />
    </>
  );
}
