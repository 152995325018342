import React, { useState } from 'react';
import { 
  MapContainer, 
  TileLayer, 
  Marker, 
  Popup, 
  Circle, 
  useMapEvents 
} from 'react-leaflet';
import { 
  Box, 
  Paper, 
  Typography, 
  Container, 
  Button, 
  ButtonGroup 
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Custom marker icon
const customIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Location finder component
const LocationFinder = ({ onLocationFound }) => {
  useMapEvents({
    locationfound(e) {
      onLocationFound(e.latlng);
    },
  });
  return null;
};

const AdvancedLocationMap = () => {
  const position = [17.479773058193373, 78.56345962480421];
  const [userLocation, setUserLocation] = useState(null);
  const [map, setMap] = useState(null);

  const handleFindLocation = () => {
    map.locate();
  };

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              Our Location
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Visit us at our office in Hyderabad, India
            </Typography>
          </Box>
          
          <ButtonGroup variant="outlined" size="small">
            <Button onClick={handleZoomIn}>
              <ZoomInIcon />
            </Button>
            <Button onClick={handleZoomOut}>
              <ZoomOutIcon />
            </Button>
            <Button onClick={handleFindLocation}>
              <MyLocationIcon />
            </Button>
          </ButtonGroup>
        </Box>

        <Box sx={{ 
          height: 400, 
          width: '100%',
          position: 'relative',
          '& .leaflet-container': { 
            height: '100%', 
            width: '100%',
            borderRadius: 1
          }
        }}>
          <MapContainer 
            center={position} 
            zoom={13} 
            scrollWheelZoom={true}
            ref={setMap}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            
            {/* Office marker */}
            <Marker position={position} icon={customIcon}>
              <Popup>
                <Typography variant="subtitle2">Super Teacher</Typography>
                <Typography variant="body2">
                  123 Education Street,<br />
                  Hyderabad, Telangana 500081
                </Typography>
              </Popup>
            </Marker>

            {/* Service area circle */}
            <Circle
              center={position}
              pathOptions={{ fillColor: 'blue', fillOpacity: 0.1 }}
              radius={1000}
            />

            {/* User location marker */}
            {userLocation && (
              <Marker position={[userLocation.lat, userLocation.lng]}>
                <Popup>You are here!</Popup>
              </Marker>
            )}

            <LocationFinder onLocationFound={setUserLocation} />
          </MapContainer>
        </Box>

        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
          <LocationOnIcon color="primary" />
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Address
            </Typography>
            <Typography variant="body2" color="text.secondary">
              123 Education Street<br />
              Hyderabad, Telangana 500081<br />
              India
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default AdvancedLocationMap;