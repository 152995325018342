import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import About from "../2_about/About";
import { DzCourseHeader } from "../1_home/DZ_Heading";
import { Box } from "@mui/material";
import Map from "./Map";
import ContactForm from "./ContactForm";
export default function AboutLayout() {
  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>
      <br></br> 
      <br></br>  
      <br></br>  
      <br></br>  
      <br></br>  
      <br></br>  
      <br></br>  
      <br></br>  
      <br></br>   
  
      <Box>
        <ContactForm />
      </Box>
      {/* ABoutusHome   */}
      <DzCourseHeader text="Our Location" />
      <Map />

      {/* <KeyOfferings/> */}
      {/* Footer*/}

      {/* ABOUT ----------------------------------------- */}
      {/* HOMEPAGE ----------------------------------------- */}
      {/* HOMEPAGE ----------------------------------------- */}
      {/* HOMEPAGE ----------------------------------------- */}

      <Footer />
    </>
  );
}
