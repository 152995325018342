import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import { Box, Grid, Container } from "@mui/material";
import EventDisplay from "./EventDisplay";
import EventList from "./EventCard";
import { DzCourseHeader } from "../1_home/DZ_Heading";

export default function AboutLayout() {
  return (
    <>
      {/* Root Container to Prevent Overflow */}
      <Box sx={{ overflowX: 'hidden' }}>
        <Header />
        
        {/* Main Layout with Padding for Header Space */}
        <Box sx={{ pt: { xs: 18, sm: 19, md: 20 } }}>
          <Container maxWidth="xl" sx={{ px: { xs: 2, sm: 3 } }}>
          <DzCourseHeader text="Latest Event" />

            <Grid
              container
              spacing={3}
              sx={{
                minHeight: "100vh",
                pt: 2,
                mx: 0, // Prevent extra margin
              }}
            >
              {/* Left Column - EventDisplay */}
              <Grid item xs={12} md={7} sx={{ maxWidth: '100%' }}>
                <EventDisplay />
              </Grid>

              {/* Right Column - EventList */}
              <Grid item xs={12} md={5} sx={{ maxWidth: '100%' }}>
                <EventList />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Footer />
      </Box>
    </>
  );
}
