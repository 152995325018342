import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import About from "./About";
import { Box } from "@mui/material";

export default function AboutLayout() {
  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>

      {/* Add top padding to the container */}
      <Box sx={{ pt: { xs: 16, sm: 17, md: 18 } }}>
        {/* AboutUsHome */}
        <About />
      </Box>

      <Footer />
    </>
  );
}
