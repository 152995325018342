import React, { useState } from 'react';
import { 
  Container, Typography, Button, Paper, 
  Alert, LinearProgress, Box, Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Papa from 'papaparse';

const Input = styled('input')({
  display: 'none',
});

const CSVUploadPage = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
    setSuccess(false);
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file first.');
      return;
    }

    setUploading(true);
    setError(null);
    setSuccess(false);
    setProgress(0);

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        if (results.errors.length > 0) {
          setError(`Error parsing CSV file: ${results.errors[0].message}`);
          setUploading(false);
          return;
        }

        try {
          for (let i = 0; i < results.data.length; i++) {
            const entry = results.data[i];
            
            // Convert boolean string to actual boolean
            if (entry.TDSIfApplicable) {
              entry.TDSIfApplicable = entry.TDSIfApplicable.toLowerCase() === 'true';
            }

            console.log(`Uploading entry ${i + 1}:`, entry);  // Log the entry being uploaded

            const response = await fetch('http://localhost:3001/api/book', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(entry),
            });

            if (!response.ok) {
              const errorText = await response.text();
              console.error(`Error response for entry ${i + 1}:`, errorText);
              throw new Error(`Failed to add entry ${i + 1}. Server response: ${errorText}`);
            }

            setProgress(((i + 1) / results.data.length) * 100);
          }

          setSuccess(true);
        } catch (err) {
          console.error('Error during upload:', err);
          setError(`Error uploading entries: ${err.message}`);
        } finally {
          setUploading(false);
        }
      },
    });
  };

  const generateTemplateCSV = () => {
    const headers = [
      'FY', 'Month', 'RecordSN', 'TransactionID', 'ValueDate', 'TxnPostedDate',
      'ChequeNo', 'Description', 'Description2', 'CrDr', 'Balance',
      'TransactionAmount', 'PartyName', 'PartyType', 'ServiceType',
      'ProductName', 'SalesPerson', 'ModeOfTransaction', 'Cat0', 'Cat1',
      'Cat2', 'Cat3', 'Cat4', 'Cat5', 'Department', 'Product',
      'CompleteDescription', 'InvoiceNumber', 'InvoiceLinks', 'Comment',
      'TDSIfApplicable'
    ];

    const csvContent = Papa.unparse({
      fields: headers,
      data: [
        headers.reduce((acc, header) => {
          acc[header] = header === 'TDSIfApplicable' ? 'false' : 'Sample ' + header;
          return acc;
        }, {})
      ]
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'bookkeeping_template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Upload CSV
      </Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Download a{' '}
            <Link
              component="button"
              variant="body1"
              onClick={generateTemplateCSV}
            >
              template CSV file
            </Link>
            {' '}to see the expected format.
          </Typography>
          <Input
            accept=".csv"
            id="csv-file-input"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="csv-file-input">
            <Button variant="contained" component="span">
              Select CSV File
            </Button>
          </label>
          {file && (
            <Typography variant="body1" sx={{ mt: 1 }}>
              Selected file: {file.name}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file || uploading}
        >
          Upload
        </Button>
        {uploading && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            CSV file uploaded successfully!
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default CSVUploadPage;