import * as React from 'react';
import axios from 'axios';
import { useEffect } from 'react';

export function Test() {
  const [data, setData] =React.useState("....")
  const [message, setMessage] =React.useState("....")
  const [err, setErr] =React.useState("....")
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`https://superteacher/api/`);
        setData(response.data);
        setMessage(response.data.message);
        
       
      } catch (err) {
        console.error("Error fetching data:", err);
        setErr(err);
      } 
    };

    fetchData();
  }, []);

  return(<> {data}
  {message}
  
  {err}
  
   </>)
}