import { Box } from "@mui/material";
import React, { useState } from "react";

export function FolderBox(props) {
  return (
    <Box>
      <Box
        sx={{
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "orange",
            borderRadius: "5px 25px 5px 5px",
            height: "100px",
            width: "100px",
          }}
        ></Box>

        <Box>{props.folderName}</Box>
      </Box>
    </Box>
  );
}
