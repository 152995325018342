import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import {
  Container,
  Box,
  CircularProgress,
  Alert,
  Paper,
  Typography,
//   Breadcrumbs,
//   Link,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CourseDetails = () => {
  const { coursesid } = useParams();
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(`http://superteacher.co.in/api/courses-details/${coursesid}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch course details');
        }
        
        const data = await response.json();
        console.log("Received course data:", data);

        if (!data.data) {
          throw new Error('Course data is empty');
        }

        setCourseDetails(data.data);
      } catch (error) {
        console.error("Error fetching course details:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (coursesid) {
      fetchCourseDetails();
    }
  }, [coursesid]);

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress sx={{ color: '#3a96d7' }} />
        </Box>
      );
    }

    if (error) {
      return (
        <Alert 
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => navigate('/')}
            >
              Back to Courses
            </Button>
          }
        >
          {error}
        </Alert>
      );
    }

    return (
      <>
        {/* <Box sx={{ mb: 4 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component="button"
              onClick={() => navigate('/')}
              sx={{ 
                textDecoration: 'none', 
                color: '#666',
                '&:hover': { color: '#3a96d7' }
              }}
            >
              Courses
            </Link>
            <Typography color="text.primary">
              {courseDetails?.courseName || 'Course Details'}
            </Typography>
          </Breadcrumbs>
        </Box> */}

        <Button 
          startIcon={<ArrowBackIcon />} 
          onClick={() => navigate('/')}
          sx={{ 
            mb: 4,
            color: '#3a96d7',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'rgba(58, 150, 215, 0.04)'
            }
          }}
        >
          Back to Courses
        </Button>

        {courseDetails ? (
          <>
            <Paper 
              elevation={0} 
              sx={{ 
                p: { xs: 2, sm: 3, md: 4 }, 
                borderRadius: 2,
                backgroundColor: '#fff',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '4px',
                  height: '100%',
                  backgroundColor: '#3a96d7',
                },
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                mb: 4  // Add margin bottom for spacing before the enroll button
              }}
            >
              <Box
                className="course-content"
                sx={{
                  '& img': { 
                    maxWidth: '100%', 
                    height: 'auto',
                    borderRadius: 1,
                    display: 'block',
                    margin: '1rem 0'
                  },
                  '& h1, & h2, & h3, & h4, & h5, & h6': {
                    color: '#2c3e50',
                    fontWeight: 600,
                    marginBottom: '1rem',
                    marginTop: '1.5rem'
                  },
                  '& h1': { fontSize: '2rem' },
                  '& h2': { fontSize: '1.75rem' },
                  '& h3': { fontSize: '1.5rem' },
                  '& p': {
                    marginBottom: '1rem',
                    lineHeight: 1.6,
                    color: '#4a5568'
                  },
                  '& ul, & ol': {
                    marginBottom: '1rem',
                    paddingLeft: '1.5rem'
                  },
                  '& li': {
                    marginBottom: '0.5rem',
                    color: '#4a5568'
                  },
                  '& a': {
                    color: '#3a96d7',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  },
                  '& blockquote': {
                    borderLeft: '4px solid #e2e8f0',
                    paddingLeft: '1rem',
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: '1rem',
                    color: '#718096',
                    fontStyle: 'italic'
                  },
                  '& table': {
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: '1rem'
                  },
                  '& th, & td': {
                    border: '1px solid #e2e8f0',
                    padding: '0.75rem',
                    textAlign: 'left'
                  },
                  '& th': {
                    backgroundColor: '#f7fafc'
                  },
                  '& pre': {
                    backgroundColor: '#f7fafc',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                    overflowX: 'auto',
                    marginBottom: '1rem'
                  },
                  '& code': {
                    backgroundColor: '#f7fafc',
                    padding: '0.25rem 0.5rem',
                    borderRadius: '0.25rem',
                    fontSize: '0.875rem'
                  }
                }}
                dangerouslySetInnerHTML={{ __html: courseDetails.knowMore }}
              />
            </Paper>

            {/* Enroll Now Button */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mb: { xs: 4, sm: 5, md: 6 }  // Responsive margin bottom
              }}
            >
              <Button
                variant="contained"
                href={courseDetails.enrollNow}
                sx={{
                  bgcolor: '#fecc00',
                  color: 'white',
                  py: { xs: 1.5, sm: 2 },  // Responsive padding
                  px: { xs: 4, sm: 6 },     // Responsive padding
                  fontSize: { xs: '1rem', sm: '1.1rem' },  // Responsive font size
                  fontWeight: 600,
                  '&:hover': {
                    bgcolor: '#e3b800',
                  },
                  textTransform: 'none',
                  borderRadius: 2,
                  boxShadow: '0 2px 8px rgba(254, 204, 0, 0.3)',
                }}
              >
                Enroll Now
              </Button>
            </Box>
          </>
        ) : (
          <Typography 
            variant="h6" 
            sx={{ textAlign: 'center', color: '#666' }}
          >
            Course details not found.
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>

      {/* Main Content */}
      <Box sx={{ 
        pt: { xs: 16, sm: 17, md: 18 },  // Responsive top padding
        px: { xs: 2, sm: 4, md: 6 },     // Responsive horizontal padding
        minHeight: '100vh',
        backgroundColor: '#f8fafc'  // Light background color
      }}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {renderContent()}
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default CourseDetails;