import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Avatar, Grid } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';

const testimonials = [
  {
    index: 1,
    quote: "1............The quality of work and the innovation of SuperTeacher Team amazes me. Everytime they come with an offering it really worths the value you pay and the time you spend.",
    author: "Mrs. Hira Prasad",
    title: "Headmistress,",
    school: "Birla High School, Kolkata"
  },
  {
    index: 2,
    quote: "2......The quality of work and the innovation of SuperTeacher Team amazes me. Everytime they come with an offering it really worths the value you pay and the time you spend.",
    author: "Mrs. Hira Prasad",
    title: "Headmistress,",
    school: "Birla High School, Kolkata"
  },
  {
    index: 3,
    quote: "3.     The quality of work and the innovation of SuperTeacher Team amazes me. Everytime they come with an offering it really worths the value you pay and the time you spend.",
    author: "Mrs. Hira Prasad",
    title: "Headmistress,",
    school: "Birla High School, Kolkata"
  },
];

function SwipeableTestimonialCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [swiper, setSwiper] = React.useState(null);
  const maxSteps = testimonials.length;

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handleBack = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleSlideChange = () => {
    if (swiper) {
      setActiveStep(swiper.realIndex);
    }
  };

  return (
    <Box bgcolor="#f5f5f5" py={2}>
      <Grid 
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ width: '100%' }}>
          <Swiper
            onSwiper={setSwiper}
            onSlideChange={handleSlideChange}
            modules={[Autoplay, Navigation]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={500}
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial.index}>
                <Box sx={{ p: 2 }}>
                  <Box textAlign='center' mx={{xs:'4%', md:'20%'}}>
                    <Typography component="div" sx={{ fontStyle: 'italic' }}>
                      "{testimonial.quote}"
                    </Typography>
                  </Box>
                  
                  <Box textAlign='center' justifyItems="center" pt={2}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Avatar 
                        src="https://superteacher.in/public/educator_image/dummy.png"
                        sx={{ width: 100, height: 100 }}
                      />
                    </Grid>
                  </Box>

                  <Box textAlign='center' sx={{mt:2}}>
                    <Typography component="div" sx={{ fontWeight: 'bold' }}>
                      {testimonial.author}
                    </Typography>
                  </Box>
                  <Box textAlign='center'>
                    <Typography component="div">
                      {testimonial.title}
                    </Typography>
                  </Box>
                  <Box textAlign='center'>
                    <Typography component="div">
                      {testimonial.school}
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <MobileStepper
          sx={{ 
            bgcolor: "inherit",
            '& .MuiMobileStepper-dot': {
              backgroundColor: 'darkgray'
            },
            '& .MuiMobileStepper-dotActive': {
              backgroundColor: '#fecc00'
            }
          }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{ 
                color: 'black',
                '&.Mui-disabled': {
                  opacity: 0.5
                }
              }}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button 
              size="small" 
              onClick={handleBack} 
              disabled={activeStep === 0}
              sx={{ 
                color: 'black',
                '&.Mui-disabled': {
                  opacity: 0.5
                }
              }}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Grid>
    </Box>
  );
}

export default SwipeableTestimonialCarousel;