import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, List, ListItem, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, MenuItem
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import BackButton from '../0_microelements/A3_BackButton';
import { Header } from "../0_layout/HeaderFooter";
import { Box } from "@mui/material";

const MonthManagement = () => {
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMonth, setCurrentMonth] = useState({ month: '', month_un: '', year: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchMonths();
    fetchYears();
  }, []);

  const fetchMonths = async () => {
    try {
      const response = await fetch('http://localhost:3001/api/months');
      const data = await response.json();
      setMonths(data);
    } catch (error) {
      console.error('Error fetching months:', error);
    }
  };

  const fetchYears = async () => {
    try {
      const response = await fetch('http://localhost:3001/api/years');
      const data = await response.json();
      setYears(data);
    } catch (error) {
      console.error('Error fetching years:', error);
    }
  };

  const handleOpenDialog = (month = null) => {
    if (month) {
      setCurrentMonth(month);
      setIsEditing(true);
    } else {
      setCurrentMonth({ month: '', month_un: '', year: '' });
      setIsEditing(false);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentMonth({ month: '', month_un: '', year: '' });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentMonth(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const url = isEditing
        ? `http://localhost:3001/api/months/${currentMonth._id}`
        : 'http://localhost:3001/api/months';
      const method = isEditing ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(currentMonth)
      });
      if (!response.ok) throw new Error('Failed to save month');
      fetchMonths();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving month:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:3001/api/months/${id}`, {
        method: 'DELETE'
      });
      if (!response.ok) throw new Error('Failed to delete month');
      fetchMonths();
    } catch (error) {
      console.error('Error deleting month:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{mt:'150px'}}>
      <Typography variant="h4" component="h1" gutterBottom>
        Month Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
      >
        Add New Month
      </Button>
      <List>
        {months.map((month) => (
          <ListItem
            key={month._id}
            secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(month)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(month._id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={`${month.month} (${month.month_un})`}
              secondary={`Year: ${month.year.year}`}
            />
          </ListItem>
        ))}
      </List>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Edit Month' : 'Add New Month'}</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="dense"
            name="month"
            label="Month"
            fullWidth
            value={currentMonth.month}
            onChange={handleInputChange}
          >
            {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((m) => (
              <MenuItem key={m} value={m}>{m}</MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="month_un"
            label="Month Number"
            type="number"
            fullWidth
            value={currentMonth.month_un}
            onChange={handleInputChange}
          />
          <TextField
            select
            margin="dense"
            name="year"
            label="Year"
            fullWidth
            value={currentMonth.year}
            onChange={handleInputChange}
          >
            {years.map((year) => (
              <MenuItem key={year._id} value={year._id}>{year.year}</MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEditing ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

       {/* Back Button */}
       <Box sx={{textAlign:'end', my:'50px'}}>
        <BackButton color='#f5f5f5'/>
      </Box>
      
    </Container>
  );
};

export default MonthManagement;