import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

const JobCard = ({ jobRole, location }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleKnowMore = () => {
    // Convert the job role to URL-friendly format
    const urlFriendlyJobRole = jobRole
      .toLowerCase()
      .replace(/\s+/g, '-');  // Replace spaces with hyphens
    
    navigate(`/career/${urlFriendlyJobRole}`);
  };

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: { xs: '100%', sm: 700, md: 900 },
        height: { xs: 'auto', sm: 160 },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: { xs: 2, sm: 3 },
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '4px',
          height: '100%',
          backgroundColor: '#3a96d7',
        },
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'flex-start', sm: 'center' },
          justifyContent: 'space-between',
          height: '100%',
          p: { xs: 2, sm: 3 },
          '&:last-child': {
            pb: { xs: 2, sm: 3 }
          }
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: '100%'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' },
              fontWeight: 600,
              color: '#2c3e50',
              mb: { xs: 1, sm: 1.5 },
              lineHeight: 1.3
            }}
          >
            {jobRole}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 2, sm: 0 }
            }}
          >
            <LocationOnIcon
              sx={{
                mr: 1,
                color: '#666',
                fontSize: '1.1rem'
              }}
            />
            <Typography
              sx={{
                color: '#666',
                fontSize: { xs: '0.9rem', sm: '1rem' }
              }}
            >
              {location}
            </Typography>
          </Box>
        </Box>

        {!isMobile && <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}
        
        {isMobile && <Divider sx={{ width: '100%', my: 2 }} />}

        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
            minWidth: { sm: '120px' }
          }}
        >
          <Button
            variant="contained"
            onClick={handleKnowMore}
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: '#fecc00',
              color: 'white',
              px: { xs: 2, sm: 3 },
              py: 1,
              textTransform: 'none',
              fontSize: { xs: '0.875rem', sm: '1rem' },
              fontWeight: 500,
              borderRadius: 2,
              width: { xs: '100%', sm: 'auto' },
              '&:hover': {
                backgroundColor: '#e3b800',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(254, 204, 0, 0.3)',
              },
              transition: 'all 0.2s ease',
            }}
          >
            Know More
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default JobCard;