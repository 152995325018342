import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import { DzCourseHeader } from "../1_home/DZ_Heading";
import { Box, Divider, Typography } from "@mui/material";
import JobRoles from "./JobRoles";

export default function AboutLayout() {
  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>

      {/* About Us Header */}
      
      <DzCourseHeader text="About Us" />
      

      <Divider sx={{ my: 3, bgcolor: "#559ef2", width: "75%", height: '1px', margin: "0 auto" }} />

      {/* About Us Description with padding */}
      <Box sx={{ px: 20, py: 4, mb: 4 }}>
        <Typography variant="body1" color="text.secondary">
          SuperTeacher Edureforms Pvt Ltd is a leading provider of computer
          science curriculum solutions for grades 1 to 10, for various boards of
          education. We are dedicated to revolutionizing the way computer
          science is taught in schools, empowering students with essential
          competencies for the digital age.{" "}
        </Typography>
      </Box>

      <DzCourseHeader text="We Are Hiring" />
      <Divider sx={{ my: 3, bgcolor: "#559ef2", width: "75%", height: '1px', margin: "0 auto" }} />

      <JobRoles />

      <Footer />
    </>
  );
}
