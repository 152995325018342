import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, List, ListItem, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Snackbar,
  CircularProgress,
  Box
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import BackButton from '../0_microelements/A3_BackButton';
import { Header } from "../0_layout/HeaderFooter";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const YearManagement = () => {
  const [years, setYears] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentYear, setCurrentYear] = useState({ financialYear: '', description: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchYears();
  }, []);

  const fetchYears = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://localhost:3001/api/years');
      if (!response.ok) {
        throw new Error('Failed to fetch years');
      }
      const data = await response.json();
      setYears(data);
    } catch (error) {
      console.error('Error fetching years:', error);
      setSnackbar({ open: true, message: 'Failed to fetch years: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (year = null) => {
    if (year) {
      setCurrentYear(year);
      setIsEditing(true);
    } else {
      setCurrentYear({ financialYear: '', description: '' });
      setIsEditing(false);
    }
    setOpenDialog(true);
    setError('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentYear({ financialYear: '', description: '' });
    setIsEditing(false);
    setError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentYear(prev => ({ ...prev, [name]: value }));
  };

  const validateFinancialYear = (fy) => {
    const regex = /^\d{4}-\d{2}$/;
    if (!regex.test(fy)) return false;
    const startYear = parseInt(fy.substr(0, 4));
    const endYear = parseInt(fy.substr(5, 2)) + 2000;
    return endYear === startYear + 1;
  };

  const handleSubmit = async () => {
    if (!validateFinancialYear(currentYear.financialYear)) {
      setError('Invalid financial year format. Please use YYYY-YY format.');
      return;
    }
  
    setLoading(true);
    try {
      const url = isEditing
        ? `http://localhost:3001/api/years/${currentYear._id}`
        : 'http://localhost:3001/api/years';
      const method = isEditing ? 'PUT' : 'POST';
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          financialYear: currentYear.financialYear,
          description: currentYear.description
        })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to save year');
      }
      await fetchYears();
      handleCloseDialog();
      setSnackbar({ open: true, message: `Year ${isEditing ? 'updated' : 'added'} successfully`, severity: 'success' });
    } catch (error) {
      console.error('Error saving year:', error);
      setError(error.message);
      setSnackbar({ open: true, message: `Failed to ${isEditing ? 'update' : 'add'} year: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this year? This action cannot be undone.')) {
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`http://localhost:3001/api/years/${id}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error('Failed to delete year');
      }
      await fetchYears();
      setSnackbar({ open: true, message: 'Year deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting year:', error);
      setSnackbar({ open: true, message: 'Failed to delete year: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{mt:'150px'}}>
    <Header/>

    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Financial Year Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
      >
        Add New Financial Year
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {years.map((year) => (
            <ListItem
              key={year._id}
              secondaryAction={
                <>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleOpenDialog(year)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(year._id)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemText
                primary={year.financialYear}
                secondary={year.description}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Edit Financial Year' : 'Add New Financial Year'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="financialYear"
            label="Financial Year (YYYY-YY)"
            type="text"
            fullWidth
            value={currentYear.financialYear}
            onChange={handleInputChange}
            error={!!error}
            helperText={error}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={currentYear.description}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? 'Saving...' : (isEditing ? 'Update' : 'Add')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Back Button */}
      <Box sx={{textAlign:'end', my:'50px'}}>
        <BackButton color='#f5f5f5'/>
      </Box>
    
    
    </Container>
    </Box>
  );
};

export default YearManagement;