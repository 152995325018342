import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Important } from "./A_Carousel";
import { Box } from "@mui/material";
import FullWidthCarousel from "./B_Offerings";
import { Footer } from "../0_layout/Footer";
import { DzCourseHeader } from "./DZ_Heading";
import SwipeableTestimonialCarousel from "./E_Testimonial";
import ColorCardSection from "./F_ColorCardSection";
import { GClients } from "./G_Clients";
import { HNumbers } from "./H_Numbers";
import PopularCourse from "./D_CoursesAtHome";

export default function Layout() {
  return (
    <>
      <Box>
        <Header />
      </Box>

      <Box 
        sx={{ 
          pt: 20, 
          px: { xs: "3%", md: "8%" },
          mb: 10 // Added margin bottom
        }}
      >
        <Important />
      </Box>

      <Box sx={{ mb: 5 }}> {/* Added margin for FullWidthCarousel */}
        <FullWidthCarousel />
      </Box>

      <Box sx={{ mb: 5 }}> {/* Added margin for Course Header */}
        <DzCourseHeader text="Offerings" />
      </Box>

      <Box 
        sx={{ 
          px: { xs: "3%", md: "8%" },
          mb: 20 // Added margin bottom
        }}
      >
        <ColorCardSection />
      </Box>

      

      <Box sx={{ mb: 5 }}> {/* Added margin for Course Header */}
        <DzCourseHeader text="Popular Courses" />
      </Box>

      <Box sx={{ mb: 10 }}> {/* Added margin for Popular Courses */}
        <PopularCourse />
      </Box>

      <Box sx={{ mb: 0 }}> {/* Added margin for Numbers */}
        <HNumbers />
      </Box>

      <Box sx={{ mb: 5 }}> {/* Added margin for Testimonials Header */}
        <DzCourseHeader text="Testimonials" />
      </Box>

      <Box sx={{ mb: 10 }}> {/* Added margin for Testimonials */}
        <SwipeableTestimonialCarousel />
      </Box>

      <Box sx={{ mb: 8 }}> {/* Added margin for Clients Header */}
        <DzCourseHeader text="Our Clients" />
      </Box>

      <Box sx={{ mb: 12 }}> {/* Added margin for Clients */}
        <GClients />
      </Box>

      <Footer />
    </>
  );
}