import { Box } from "@mui/material";
import React from "react";

export function DzCourseHeader(props){
    return(
        <>
            <Box sx={{pt:10, textAlign:"center"}}>
                <h1> {props.text} </h1>

            </Box>
        </>
    )
}