import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const BackButton = ({ color = 'primary' }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  // Check if the color is a valid hex code
  const isHexColor = (color) => /^#([0-9A-F]{3}){1,2}$/i.test(color);

  // Create a custom theme if a hex color is provided
  const theme = isHexColor(color)
    ? createTheme({
        palette: {
          custom: {
            main: color,
          },
        },
      })
    : undefined;

  const buttonProps = isHexColor(color)
    ? { color: 'custom' }
    : { color: color };

  const ButtonComponent = (
    <Button
      variant="contained"
      {...buttonProps}
      startIcon={<ArrowBack />}
      onClick={handleGoBack}
    >
      Back
    </Button>
  );

  return theme ? (
    <ThemeProvider theme={theme}>{ButtonComponent}</ThemeProvider>
  ) : (
    ButtonComponent
  );
};

export default BackButton;