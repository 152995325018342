import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import { Button, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Link from '@mui/material/Link';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const StyledBadge = styled(Badge)(({ theme, dotTop = 0, dotRight = 0 }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#fecc00",
    color: "#fecc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "& .MuiBadge-dot": {
    top: dotTop,
    right: dotRight,
    transform: "translate(50%, -50%)",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const CustomPositionedBadge = ({ children, dotTop, dotRight, ...props }) => {
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      variant="dot"
      dotTop={dotTop}
      dotRight={dotRight}
      {...props}
    >
      {children}
    </StyledBadge>
  );
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: "auto",
  [theme.breakpoints.down("md")]: {
    width: "140px", // Smaller width on mobile
    marginRight: 0,
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 1),
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "0.875rem",
      padding: theme.spacing(0.75, 0.75, 0.75, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    },
  },
}));
const OfferingsDropdown = ({ isMobile = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSchoolsOpen, setIsSchoolsOpen] = useState(false);
  const [isStudentsOpen, setIsStudentsOpen] = useState(false);
  const location = useLocation();
  
  const handleClick = (event) => {
    if (isMobile) {
      setIsOpen(!isOpen);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSchoolsClick = () => {
    setIsSchoolsOpen(!isSchoolsOpen);
  };

  const handleStudentsClick = () => {
    setIsStudentsOpen(!isStudentsOpen);
  };

  const handleClose = () => {
    if (isMobile) {
      setIsOpen(false);
      setIsSchoolsOpen(false);
      setIsStudentsOpen(false);
    } else {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);

  if (isMobile) {
    return (
      <>
        <Button
          color="inherit"
          onClick={handleClick}
          endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          fullWidth
          sx={{ justifyContent: 'flex-start' }}
        >
          OFFERINGS
        </Button>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }}>
              <Button 
                color="inherit" 
                fullWidth 
                href="/offerings/teachers"
                sx={{ justifyContent: 'flex-start' }}
              >
                Teachers
              </Button>
            </ListItem>
            <ListItem sx={{ pl: 4 }}>
              <Button 
                color="inherit" 
                fullWidth 
                href="/offerings/schools"
                sx={{ justifyContent: 'flex-start' }}
              >
                Schools
              </Button>
            </ListItem>
            <ListItem sx={{ pl: 4 }}>
              <Button
                color="inherit"
                fullWidth
                onClick={handleStudentsClick}
                endIcon={isStudentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                sx={{ justifyContent: 'flex-start' }}
              >
                Students
              </Button>
            </ListItem>
            <Collapse in={isStudentsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem sx={{ pl: 8 }}>
                  <Button 
                    color="inherit" 
                    fullWidth 
                    href="/offerings/students/coding"
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    Coding
                  </Button>
                </ListItem>
                <ListItem sx={{ pl: 8 }}>
                  <Button 
                    color="inherit" 
                    fullWidth 
                    href="/offerings/students/cbse-subjects"
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    CBSE Subjects
                  </Button>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          mx: 1,
          borderRadius: 20,
          height: 30,
          fontSize: "15px",
          fontFamily: "Comfortaa",
          color: location.pathname.startsWith("/offerings") ? "white" : "#3a96d7",
          bgcolor: location.pathname.startsWith("/offerings") ? "#fecc00" : "",
          fontWeight: "bold",
          "&.MuiButton-root:hover": {
            backgroundColor: "#fecc00",
            color: "white",
          },
        }}
      >
        OFFERINGS
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              minWidth: '150px',
              bgcolor: '#fffffffa',
              backdropFilter: 'blur(4px)',
            }
          }
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link href="/offerings/teachers" underline="none" color="inherit">
            Teachers
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link href="/offerings/schools" underline="none" color="inherit">
            Schools
          </Link>
        </MenuItem>
        <MenuItem 
          onClick={handleStudentsClick}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <span>Students</span>
          {isStudentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </MenuItem>
        <Collapse in={isStudentsOpen} timeout="auto" unmountOnExit>
          <MenuItem onClick={handleClose}>
            <Link 
              href="/offerings/students/coding" 
              underline="none" 
              color="inherit"
              sx={{ pl: 2 }}
            >
              Coding
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link 
              href="/offerings/students/cbse-subjects" 
              underline="none" 
              color="inherit"
              sx={{ pl: 2 }}
            >
              CBSE Subjects
            </Link>
          </MenuItem>
        </Collapse>
      </Menu>
    </>
  );
};
export function Header() {
  const [showBox, setShowBox] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust the scroll distance as needed
        setShowBox(false);
      } else {
        setShowBox(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      slotProps={{
        paper: {
          sx: {
            mt: 7,
            width: '200px',
            bgcolor: '#fffffffa',
            backdropFilter: 'blur(4px)',
          }
        }
      }}
    >
      <Box sx={{ width: '100%' }}>
        <MenuItem>
          <Button 
            color="inherit" 
            href="/home"
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            HOME
          </Button>
        </MenuItem>

        <MenuItem sx={{ flexDirection: 'column', alignItems: 'stretch', p: 0 }}>
          <OfferingsDropdown isMobile={true} />
        </MenuItem>

        <MenuItem>
          <Button 
            color="inherit"
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            <CustomPositionedBadge dotTop={-1} dotRight={-2}>
              EVENTS
            </CustomPositionedBadge>
          </Button>
        </MenuItem>

        <MenuItem>
          <Button 
            color="inherit"
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            CAREER
          </Button>
        </MenuItem>

        <MenuItem>
          <Button 
            color="inherit"
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            CONTACT
          </Button>
        </MenuItem>

        <MenuItem>
          <Button 
            color="inherit"
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            LOGIN
          </Button>
        </MenuItem>
      </Box>
    </Menu>
  );

  const StButton = (props) => {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
      <>
        <Button
          href={props.hit}
          sx={{
            mx: 1,
            borderRadius: 20,
            height: 30,
            fontSize: "15px",
            fontFamily: "Comfortaa",
            color: "#3a96d7",
            color:
              currentPath === "/" + props.text.toLowerCase()
                ? "white"
                : "#3a96d7",
            bgcolor:
              currentPath === "/" + props.text.toLowerCase() ? "#fecc00" : "",
            fontWeight: "bold",
            "&.MuiButton-root:hover": {
              backgroundColor: "#fecc00",
              color: "white",
            },
          }}
        >
          {" "}
          {props.text}{" "}
        </Button>
      </>
    );
  };

  return (
    <Box position="fixed" sx={{ flexGrow: 1, zIndex: 900 }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          width: "100%",
          p: 1,
          bgcolor: "#3a96d7",
          color: "black",
          zIndex: 1001,
          display: showBox ? "block" : "none",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{
            pl: 1,
            pr: 3,
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          <Grid
            item
            sx={{
              display: { xs: "none", md: "flex" },

              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              color: "white",
            }}
          >
            <CallIcon />
            <Box sx={{ mr: 1 }}> 1800 123 7765, 9908442000 </Box>
            <MailIcon />
            <Box sx={{ mr: 1 }}> connect@superteacher.in </Box>
          </Grid>

          <Grid item sx={{ justifyContent: "center" }}>
            <Box sx={{ justifyContent: "center" }}>
              <Link
                href="https://www.facebook.com/SuperTeacherEdu"
                sx={{ color: "white" }}
              >
                {" "}
                <FacebookIcon />{" "}
              </Link>
              <Link
                href="https://www.instagram.com/superteacheredureforms/"
                sx={{ color: "white" }}
              >
                {" "}
                <InstagramIcon />{" "}
              </Link>
              <Link
                href="https://twitter.com/SuperTeacher_ER"
                sx={{ color: "white" }}
              >
                {" "}
                <XIcon />{" "}
              </Link>
              <Link
                href=" https://www.linkedin.com/in/superteacher-edu-reforms/"
                sx={{ color: "white" }}
              >
                {" "}
                <LinkedInIcon />{" "}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <AppBar
        sx={{
          top: showBox ? 30 : 0,
          p: 2,
          bgcolor: "#ffffffc4",
          color: "black",
          backdropFilter: "blur(4px)",
          zIndex: 1000,
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          {/* Logo Section */}
          <Grid 
            item 
            xs="auto"
            sx={{ 
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src="https://superteacher.in/public/frontend/kids/images/super_teacher_logo_new.png"
              width={150}
              alt="Super Teacher Logo"
              style={{ display: 'block' }}
            />
          </Grid>

          {/* Mobile Search and Menu Section */}
          <Grid 
            item 
            xs
            sx={{ 
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: 2
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>

            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={handleMobileMenuOpen}
              sx={{ ml: 1 }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          {/* Desktop Menu Section */}
          <Grid 
            item 
            xs
            sx={{ 
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Toolbar sx={{ justifyContent: 'flex-end', padding: 0 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StButton text="HOME" hit="/home" />
                    <StButton text="ABOUT" hit="/about" />
                    <OfferingsDropdown />
                    <CustomPositionedBadge dotTop={2} dotRight={15}>
                      <StButton text="EVENTS" hit="/events" />
                    </CustomPositionedBadge>
                    <StButton text="CAREER" hit="/career" />
                    <StButton text="CONTACT" hit="/contact" />
                    <StButton text="LOGIN" hit="/login" />
                  </Box>
                </Grid>
                <Grid item>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      
      {/* Update the mobile menu styles */}
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        PaperProps={{
          sx: {
            mt: 7, // Add margin top to prevent overlap with AppBar
            width: '200px', // Fixed width for mobile menu
            bgcolor: '#fffffffa', // Slightly transparent background
            backdropFilter: 'blur(4px)',
          }
        }}
      >
        <Box sx={{ width: '100%' }}>
          <MenuItem>
            <Button 
              color="inherit" 
              href="/home"
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
            >
              HOME
            </Button>
          </MenuItem>

          <MenuItem sx={{ flexDirection: 'column', alignItems: 'stretch', p: 0 }}>
            <OfferingsDropdown isMobile={true} />
          </MenuItem>

          <MenuItem>
            <Button 
              color="inherit"
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
            >
              <CustomPositionedBadge dotTop={-1} dotRight={-2}>
                EVENTS
              </CustomPositionedBadge>
            </Button>
          </MenuItem>

          {/* Add similar styling to other menu items */}
          <MenuItem>
            <Button 
              color="inherit"
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
            >
              CAREER
            </Button>
          </MenuItem>

          <MenuItem>
            <Button 
              color="inherit"
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
            >
              CONTACT
            </Button>
          </MenuItem>

          <MenuItem>
            <Button 
              color="inherit"
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
            >
              LOGIN
            </Button>
          </MenuItem>
        </Box>
      </Menu>
    {renderMobileMenu}
    {renderMenu}
    </Box>
  );
}
