import React from 'react';
import { Box, Typography, Container, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import 'swiper/css';

// Styled Components
const NavButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  backdropFilter: 'blur(4px)',
  width: 60,
  height: 60,
  transition: 'all 0.3s ease',
  
  '& svg': {
    fontSize: 40,
    color: 'white',
    transition: 'all 0.3s ease',
  },

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
    transform: 'scale(1.1)',
    '& svg': {
      transform: 'scale(1.1)',
    },
  },

  [theme.breakpoints.down('sm')]: {
    width: 45,
    height: 45,
    '& svg': {
      fontSize: 30,
    },
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  backdropFilter: 'blur(4px)',
  color: 'white',
  borderRadius: 25,
  padding: '10px 30px',
  fontSize: '1rem',
  textTransform: 'none',
  transition: 'all 0.3s ease',
  border: '2px solid white',

  '&:hover': {
    backgroundColor: 'white',
    color: 'black',
    transform: 'translateY(-2px)',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '8px 24px',
    fontSize: '0.9rem',
  },
}));

const carouselData = [
  {
    imageUrl: 'https://superteacher.in/public/frontend/kids/images/homeslide2.jpg',
    quote: "The future belongs to those who believe in the beauty of their dreams.",
    author: "Eleanor Roosevelt",
    buttonText: "Explore Dreams",
    buttonLink: "/dreams"
  },
  {
    imageUrl: 'https://superteacher.in/public/frontend/kids/images/home2.jpg',
    quote: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
    author: "Winston Churchill",
    buttonText: "Learn More",
    buttonLink: "/success"
  },
  {
    imageUrl: 'https://superteacher.in/public/frontend/kids/images/homeslide2.jpg',
    quote: "The only way to do great work is to love what you do.",
    author: "Steve Jobs",
    buttonText: "Find Passion",
    buttonLink: "/passion"
  },
  {
    imageUrl: 'https://superteacher.in/public/frontend/kids/images/home2.jpg',
    quote: "Life is either a daring adventure or nothing at all.",
    author: "Helen Keller",
    buttonText: "Start Adventure",
    buttonLink: "/adventure"
  }
];

// Main Component
function FullWidthCarousel() {
  const [swiper, setSwiper] = React.useState(null);

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
      <Swiper
        onSwiper={setSwiper}
        modules={[Autoplay, Navigation]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        style={{ width: '100%', height: '100%' }}
      >
        {carouselData.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.3) 100%)',
                  zIndex: 1,
                },
              }}
            >
              {/* Background Image */}
              <Box
                component="img"
                src={slide.imageUrl}
                alt={slide.author}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                }}
              />

              {/* Content Overlay */}
              <Container
                maxWidth="lg"
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  pb: { xs: 8, md: 12 },
                  position: 'relative',
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    maxWidth: 'md',
                    mx: 'auto',
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      mb: 2,
                      fontWeight: '500',
                      fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' },
                      fontFamily: 'Georgia, serif',
                      fontStyle: 'italic',
                    }}
                  >
                    "{slide.quote}"
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{
                      mb: 4,
                      opacity: 0.9,
                      fontSize: { xs: '1rem', sm: '1.25rem' },
                    }}
                  >
                    — {slide.author}
                  </Typography>

                  <ActionButton href={slide.buttonLink}>
                    {slide.buttonText}
                  </ActionButton>
                </Box>
              </Container>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation Arrows */}
      <NavButton
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          left: { xs: 2, md: 4 },
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
        }}
      >
        <KeyboardArrowLeft />
      </NavButton>

      <NavButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          right: { xs: 2, md: 4 },
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
        }}
      >
        <KeyboardArrowRight />
      </NavButton>
    </Box>
  );
}

// Default export
export default FullWidthCarousel;