import React from "react";
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card as MuiCard,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Cards = ({ offering }) => {
  const navigate = useNavigate();

  const handleKnowMore = () => {
    navigate(`/courses-details/${offering.courses_id}`);
  };

  return (
    <MuiCard
      sx={{
        width: 300,
        height: 480,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
        },
      }}
    >
      <CardMedia
        component="img"
        height="180"
        image={offering.imageUrl}
        alt={offering.courseName}
        sx={{
          objectFit: "cover",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      />

      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          p: 2,
          "&:last-child": { pb: 2 },
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 600,
            mb: 1,
            lineHeight: 1.3,
            height: "2.6em",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {offering.courseName}
        </Typography>

        <Typography
          color="text.secondary"
          sx={{
            mb: 2,
            fontSize: "1rem",
            lineHeight: 1.5,
            height: "6em",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
          }}
        >
          {offering.cardsDescription}
        </Typography>

        <Box sx={{ mt: "auto" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 2 }}
            justifyContent="space-between"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: "rgba(58, 150, 215, 0.1)",
                borderRadius: 2,
                px: 1.5,
                py: 0.75,
                flex: 1,
              }}
            >
              <AccessTimeIcon sx={{ fontSize: 18, color: "#3a96d7" }} />
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: "#3a96d7",
                  fontWeight: 600,
                }}
              >
                {offering.duration}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: "rgba(254, 204, 0, 0.1)",
                borderRadius: 2,
                px: 1.5,
                py: 0.75,
                flex: 1,
              }}
            >
              <CurrencyRupeeIcon sx={{ fontSize: 18, color: '#fecc00' }} />
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: "#fecc00",
                  fontWeight: 600,
                }}
              >
                {offering.fees}
              </Typography>
            </Box>
          </Stack>

          <Divider sx={{ mb: 2 }} />

          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button
              variant="outlined"
              onClick={handleKnowMore}
              sx={{
                flex: 1,
                color: "#3a96d7",
                borderColor: "#3a96d7",
                "&:hover": {
                  borderColor: "#3a96d7",
                  backgroundColor: "rgba(58, 150, 215, 0.04)",
                },
              }}
            >
              Know More
            </Button>
            <Button
              variant="contained"
              href={offering.enrollNow}
              sx={{
                flex: 1,
                bgcolor: "#fecc00",
                color: "white",
                "&:hover": {
                  bgcolor: "#e3b800",
                },
              }}
            >
              Enroll Now
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </MuiCard>
  );
};

export default Cards;