import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const AboutUs = () => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAboutUsData = async () => {
      try {
        const response = await axios.get('http://superteacher.co.in/api/about');
        console.log('Fetched data:', response.data); // Let's see the exact structure
        
        // Check if response.data is an array and take the first item
        const data = Array.isArray(response.data) ? response.data[0] : response.data;
        setAboutUsData(data);
      } catch (err) {
        console.error('Error details:', err.response || err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutUsData();
  }, []);

  if (loading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
      Loading...
    </Box>
  );

  if (error) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4, color: 'error.main' }}>
      Error: {error}
    </Box>
  );

  if (!aboutUsData) return null;

  // Debug log to check the data structure
  console.log('About Us Data being rendered:', {
    header: aboutUsData.header,
    description: aboutUsData.description || aboutUsData.longDescription, // Check for alternative field name
    imageURL: aboutUsData.imageURL || aboutUsData.imageUrl // Check for alternative field name
  });

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 4, md: 8 },
          alignItems: 'center',
          padding: { xs: 2, md: 6 },
          bgcolor: 'white',
          borderRadius: 2,
        //   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          margin: '-3rem auto',
        }}
      >
        {/* Left side content */}
        <Box 
          sx={{
            flex: '1',
            width: '100%',
            maxWidth: { md: '50%' },
            order: { xs: 2, md: 1 }
          }}
        >
          <Typography 
            variant="h4" 
            component="h1"
            sx={{
              fontWeight: 'bold',
              marginBottom: 3,
              color: '#333',
              fontSize: { xs: '1.75rem', md: '2.5rem' }
            }}
          >
            {aboutUsData.header}
          </Typography>
          <Typography 
            variant="body1"
            sx={{
              color: '#666',
              lineHeight: 1.7,
              fontSize: { xs: '1rem', md: '1.1rem' }
            }}
          >
            {/* Check for alternative field names */}
            {aboutUsData.Description || 'No description available'}
          </Typography>
        </Box>

        {/* Right side image */}
        <Box 
          sx={{
            flex: '1',
            width: '100%',
            maxWidth: { md: '50%' },
            order: { xs: 1, md: 2 },
            display: 'flex', // Added to center the image
            justifyContent: 'center', // Center the image within its box
            alignItems: 'center' // Center vertically if needed
          }}
        >
          {/* Only render image if URL exists */}
          {(aboutUsData.imageURL) && (
            <Box
              component="img"
              src={aboutUsData.imageURL}
              alt={aboutUsData.header || 'About Us'}
              sx={{
                width: '80%',
                height: '80%', // Ensure the image takes full height of its container
                objectFit: 'contain', // Change to contain to show the entire image
                // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
              onError={(e) => {
                console.error('Image failed to load:', e);
                e.target.style.display = 'none';
              }}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default AboutUs;
