import React from "react";
import { Header } from "../0_layout/HeaderFooter";
import { Footer } from "../0_layout/Footer";
import { Box } from "@mui/material";
import StudentsCBSE from "./StudentsCBSE";

export default function offeringLayout() {
  return (
    <>
      {/* Header */}
      <Box>
        <Header />
      </Box>

      {/* Offerings   */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <StudentsCBSE />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      {/* Footer*/}
      <Footer />
    </>
  );
}
