import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const ProtectedRoute = () => {
    const token = localStorage.getItem('token');
  
    useEffect(() => {
      if (!token) {
        // Redirect to login page if token is not found
        window.location.href = '/login';
      }
    }, [token]);
  
    if (!token) {
      return <Navigate to="/login" replace />;
    }
  
    const decodedToken = jwtDecode(token);
    // Check if the token is expired or invalid
    if (decodedToken.exp * 1000 < Date.now()) {
      // Handle token expiration, e.g., redirect to login or refresh token
      return <Navigate to="/login" replace />;
    }
  
    return <Outlet />;
  };